<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-form ref="formLead">
        <v-toolbar height="40" flat class="pt-4">
          <v-icon>mdi-contacts-outline</v-icon>
          CONTACTO :: {{ datosLead.party_id }}
          <v-divider class="mx-6" vertical flat></v-divider>
          <v-btn @click="guardarLead" color="blue" small dark
            ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
          >
          <v-divider class="mx-6" vertical flat></v-divider>

          <v-btn @click="dialogCotizar = true" color="green" small dark>
            <v-icon>mdi-currency-usd</v-icon>Cotizar
          </v-btn>
        </v-toolbar>

        <v-row class="mt-1 mx-2">
          <v-col cols="3">
            <v-text-field
              v-model="datosLead.first_name"
              label="Nombres"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="datosLead.last_name"
              label="Nombres"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              label="Ciclo de vida"
              :items="estadosCv"
              item-text="description"
              item-value="status_type_id"
              v-model="datosLead.estado_cv_id"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              label="Estado"
              :items="getEstadosCv"
              item-text="description"
              item-value="status_id"
              v-model="datosLead.status_id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-1 mx-2">
          <v-col>
             
            <v-text-field
              v-model="datosLead.telefono"
              label="Teléfono"
            >
          <template v-slot:append>
            <v-btn target="_blank" :to="`/mensajes_contacto/${datosLead.telefono_wa}/${datosLead.party_id }`" small icon ><v-icon>mdi-eye</v-icon></v-btn>
          </template>
          </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="datosLead.email"
              label="Email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-1 mx-2">
          <v-col md="6" class="py-0 my-0">
            <v-select
              :rules="datosLead.num_ident != null ? requiredRule : []"
              v-model="datosLead.party_identification_type_id"
              :items="tiposIdent"
              item-text="tipo_ident"
              item-value="descripcion"
              label="Tipo de identificación"
              clearable
            ></v-select>
          </v-col>

          <v-col md="6" class="py-0 my-0">
            <v-text-field
              :rules="
                datosLead.party_identification_type_id != null
                  ? requiredRule
                  : []
              "
              v-model="datosLead.num_ident"
              label="Número de identifación"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-1 mx-2">
          <v-col cols="6" class="py-0 my-0">
            <!-- <v-text-field
              v-model="datosLead.occupation"
              label="Ocupación"
            ></v-text-field> -->
            <v-autocomplete
              label="Profesión / ocupación"
              v-model="datosLead.profesion_id"
              :items="ocupaciones"
              item-text="description"
              item-value="enum_id"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6" class="py-0 my-0">
            <v-select
              v-model="datosLead.segmento_id"
              :items="segmentos"
              item-text="nombre"
              item-value="segmento_id"
              label="Segmento"
            ></v-select>
          </v-col>
          
        </v-row>

        <v-row class="mt-1 mx-2">
          <v-col md="6" class="py-0 my-0">
            <v-select
              :items="fuentes"
              label="Fuente"
              item-text="description"
              item-value="data_source_id"
              v-model="datosLead.data_source_id"
            >
            </v-select>
          </v-col>

          <v-col md="6" class="py-0 my-0">
            <v-select
              :items="servicios"
              label="Servicio"
              item-text="nombre"
              item-value="servicio_crm_id"
              v-model="datosLead.servicio_lead_id"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="mt-1 mx-2">
          <v-col md="6" class="py-0 my-0">
            <v-select
              :items="campanias"
              label="Campaña"
              item-text="campaign_name"
              item-value="marketing_campaign_id"
              v-model="datosLead.marketing_campaign_id"
            >
            </v-select>
          </v-col>
          <v-col md="6" class="py-0 my-0">
            <v-select
              :items="getAnuncios"
              label="Anuncio"
              item-text="descripcion"
              item-value="mk_anuncio_id"
              v-model="datosLead.mk_anuncio_id"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="mt-1 mx-2">
          <v-col md="4" class="py-0 my-0">
            <v-text-field
              type="date"
              v-model="datosLead.birth_date"
              label="Fecha de nacimiento"
            ></v-text-field>
          </v-col>
          <v-col md="2" class="py-0 my-0">
            <v-text-field
              readonly
              v-model="getEdad"
              label="Edad"
            ></v-text-field>
          </v-col>

          <v-col md="6" class="py-0 my-0">
            <v-text-field
              v-model="datosLead.created_by_user_login"
              label="Creado Por"
            ></v-text-field>
          </v-col>
        </v-row>


        <v-row class="mt-1 mx-2">
          <v-col md="6" class="py-0 my-0">
            <v-select
              :items="generos"
              label="Género"
              item-text="nombre"
              item-value="genero_id"
              v-model="datosLead.gender"
            >
            </v-select>
          </v-col>

          <v-col md="6" class="py-0 my-0">
             
          </v-col>
        </v-row>

        <v-row class="mt-1 mx-2">
          <v-col md="6" class="py-0 my-0">
            <v-textarea
              v-model="datosLead.description"
              label="Observaciones"
            ></v-textarea>
          </v-col>
          <v-col md="6" class="py-0 my-0">
            <v-textarea readonly v-model="getDireccion" label="Dirección"
              ><template v-slot:prepend
                ><v-btn small @click="abrirDialogDireccion"
                  ><v-icon>mdi-map-marker</v-icon>
                </v-btn>
              </template></v-textarea
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-4">
            <v-toolbar color="green" dark class="mt-4" height="25" flat>
              <v-toolbar-title>Oportunidades</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="itemsPerPageOp"
              class="elevation-1"
              small
              hide-default-footer
              :items="opLead"
              :headers="headersOport"
            >
              <template v-slot:item.sales_opportunity_id="{ item }">
                <v-btn
                  text
                  small
                  title="abrir oportunidad"
                  :to="`/oportunidad_form/${item.sales_opportunity_id}`"
                  ><v-icon small left color="green">mdi-handshake</v-icon>
                  {{ item.sales_opportunity_id }}
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-4">
            <v-toolbar color="green" dark class="mt-4" height="25" flat>
              <v-toolbar-title>Eventos</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="10"
              class="elevation-1"
              small
              :items="eventosLead"
              :headers="headersEvento"
              key="work_effort_id"
            >
            <template v-slot:item.work_effort_id="{ item }">
                <v-btn
                  small
                  text
                  :to="`/evento_form/${item.work_effort_id}`"
                  target="_blank"
                  ><v-icon small left color="green">mdi-calendar</v-icon>
                  {{ item.work_effort_id }}
                </v-btn>
              </template>
              <template v-slot:item.sales_opportunity_id="{ item }">
                <v-btn
                  text
                  small
                  v-if="item.sales_opportunity_id != null"
                  title="abrir oportunidad"
                  :to="`/oportunidad_form/${item.sales_opportunity_id}`"
                >
                  <v-icon small left color="green">mdi-handshake</v-icon>
                  {{ item.sales_opportunity_id }}
                </v-btn>
              </template>
              <template v-slot:item.fecha="{ item }">
                {{ item.fecha }} &nbsp; || &nbsp;
                {{ item.hora.split(":")[0] + ":" + item.hora.split(":")[1] }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-4">
            <v-toolbar 
              color="green" 
              dark 
              class="mt-4" 
              height="25" 
              flat
            >
              <v-toolbar-title>Ordenes de servicio</v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :items-per-page="itemsPerPageEv"
              class="elevation-1"
              small
              hide-default-footer
              :items="ordenesServ"
              :headers="headersOrdenesServ"
            >
              <template v-slot:item.orden_servicio_id="{ item }">
                <v-btn
                  small
                  text
                  :to="`/genera-orden-servicio/${item.quote_id}/${item.orden_servicio_id}/${item.paciente_id}`"
                  target="_blank"
                  ><v-icon small left color="green">mdi-room-service</v-icon>
                  {{ item.orden_servicio_id }}
                </v-btn>
              </template>

              <template v-slot:item.paciente="{ item }">
                  {{ item.paciente }} :: {{ item.paciente_id }}
              </template>
              
              <template v-slot:item.cliente="{ item }">
                  {{ item.cliente }} :: {{ item.cliente_id }}
              </template>

              <template v-slot:item.os_fecha="{ item }">
                {{ item.os_fecha }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- DIALOG CREAR COTIZACION LEAD -->
    <v-dialog 
      v-model="dialogCotizar" 
      width="500"
    >
      <v-form ref="formCrearOportunidad">
        <v-card class="pa-0 ma-0">
          <v-toolbar 
            dark 
            color="blue" 
            height="40" 
            flat
          >
            Crear cotización
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogCotizar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="pa-4">
            <v-col cols="12">
              <v-select
                :rules="requiredRule"
                v-model="product_store_id"
                label="Establecimiento"
                :items="tiendas"
                item-text="store_name"
                item-value="product_store_id"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                :rules="requiredRule"
                v-model="pipeline_id"
                label="Pipeline"
                :items="pipelines"
                item-text="pipeline_name"
                item-value="pipeline_id"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="opp_cot_id"
                label="Asociar con cotización #"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions class="pb-4 pl-4">
            <v-btn 
              small 
              dark 
              color="green" 
              @click="crearOportunidad()"
            >
              <v-icon left>mdi-content-save</v-icon> Crear oportunidad
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOG DIRECCION -->
    <v-dialog 
      v-model="dialogDireccion" 
      width="500"
    >
      <v-form ref="formCrearOportunidad">
        <v-card class="pa-0 ma-0">
          <v-toolbar 
            dark 
            color="blue" 
            height="40" 
            flat
          >
            Dirección
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogDireccion = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="pa-1 ma-0">
            <v-col cols="12">
              <v-autocomplete
                :rules="requiredRule"
                label="Provincia"
                v-model="datosLead.state_province_geo_id"
                :items="provincias"
                item-text="geo_name"
                item-value="geo_id"
                @change="cargarGeo('parroquias-provincia')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :rules="requiredRule"
                label="Ciudad"
                v-model="datosLead.parroquia_geo_id"
                :items="cantones"
                item-text="parroquia"
                item-value="parroquia_id"
                @change="cargarGeo('sectores-canton')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :rules="this.sectores.length == 0 ? [] : requiredRule"
                label="Sector"
                v-model="datosLead.sector"
                :items="sectores"
                item-text="nombre"
                item-value="id"
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Dirección"
                v-model="datosLead.address1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-toolbar flat>
            <v-btn 
              small 
              color="green" 
              dark 
              @click="guardarDireccion"
            > 
              <v-icon small>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-btn
              small
              text
              color="blue"
              dark
              @click="dialogDireccion = false"
              class="mx-4"
            >
                <v-icon small>mdi-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "ContactoForm",
  components: {},
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 20,
    itemsPerPageOp: 20,
    itemsPerPageEv: 20,
    pageCount: 0,
    overlay: false,
    required: [(v) => !!v || "El campo es requerido"],
    tiposIdent: [
      { tipo_ident: "CEDULA", descripcion: "CEDULA" },
      { tipo_ident: "RUC", descripcion: "RUC" },
      { tipo_ident: "PASAPORTE", descripcion: "PASAPORTE" },
    ],
    tipoLista: [
      { lista_id: "LC_MISCONTACTOS", descripcion: "Mis contactos" },
      {
        lista_id: "LC_DIRECCIONES",
        descripcion: "Direcciones",
      },
    ],
    listaActual: "LC_MISCONTACTOS",
    headers: [
      { text: "Código", align: "start", value: "party_id" },
      { text: "Nombres", align: "start", value: "cliente_nombres" },
      { text: "Etapa Ciclo V.", align: "start", value: "estado_ciclo_vida" },
      { text: "Estado", align: "start", value: "estado" },
      { text: "Asignado a", align: "start", value: "created_by_user_login" },
    ],

    leads: [],

    dialogoLead: false,
    datosLead: {
      party_id: "",
      status_id: "",
      data_source_id: "",
      acepta_recordatorios: "",
      created_date: "",
      created_by_user_login: "",
      description: "",
      party_type_id: "",
      first_name: "",
      last_name: "",
      telefono: "",
      email: "",
      mk_anuncio_id: null,
      marketing_campaign_id: "",
    },

    busContacto: "",
    busCodigo: "",
    busEtapa: "",
    busEstado: "",
    busAsignado: "",
    headersOport: [
      { text: "Oportunidad", align: "start", value: "sales_opportunity_id" },
      { text: "Estado", align: "start", value: "op_estado" },
      { text: "Creada por", align: "start", value: "created_by_user_login" },
      { text: "Fecha", align: "start", value: "estimated_close_date" },
    ],

    opLead: [],
    headersEvento: [
      { text: "Evento", align: "start", value: "work_effort_id" },
      { text: "Oportunidad", align: "start", value: "sales_opportunity_id" },
      { text: "Tipo", align: "start", value: "work_effort_name" },
      { text: "Estado", align: "start", value: "estado" },
      { text: "Fecha de inicio", align: "start", value: "fecha" },
      { text: "Creada por", align: "start", value: "created_by_user_login" },
    ],
    eventosLead: [],

    headersOrdenesServ: [
      { text: "Orden servicio", align: "start", value: "orden_servicio_id" },
      { text: "Cliente", align: "cliente", value: "cliente" },
      { text: "Paciente", align: "paciente", value: "paciente" },
      { text: "Fecha", align: "start", value: "created_stamp" },
      { text: "Estado", align: "start", value: "estado" },
      { text: "Tipo de servicio", align: "start", value: "tipo_servicio" },
    ],
    ordenesServ: [],

    cotizacionesLead: [],
    fuentes: [],
    campanias: [],
    anuncios: [],
    servicios: [],
    estados: [],
    estadosCv: [],
    dialogCrearLead: false,

    pipelines: [],
    tiendas: [],
    product_store_id: "",
    pipeline_id: "",
    opp_cot_id: "",
    dialogCotizar: false,
    dialogDireccion: false,
    parroquias: [],
    cantones: [],
    provincias: [],
    sectores: [],
    segmentos: [],
    generos: [],
    ocupaciones: []
  }),
  computed: {

    ...mapState("master", ["loadingBtn", "companies"]),

    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

    ...mapState("master", ["loadingTable", "tenantId", "user"]),

    getDireccion() {
      let dir = "";
      let prov = this.datosLead.provincia;
      let parr = this.datosLead.parroquia;
      let sector = this.datosLead.sector;

      if (this.datosLead.provincia == undefined) {
        prov = "";
      }
      if (this.datosLead.parroquia == undefined) {
        parr = "";
      }
      if (this.datosLead.sector == undefined) {
        sector = "";
      }
      dir = prov + "\n";
      dir += parr + "\n";
      dir += sector + "\n";
      dir += this.datosLead.address1 + "\n";

      return dir;
    },
    getEdad() {
      let birthDate = new Date(this.datosLead.birth_date);
      let otherDate = new Date();

      var years = otherDate.getFullYear() - birthDate.getFullYear();

      if (
        otherDate.getMonth() < birthDate.getMonth() ||
        (otherDate.getMonth() == birthDate.getMonth() &&
          otherDate.getDate() <= birthDate.getDate())
      ) {
        years--;
      }
      if (this.datosLead.birth_date == null) return "";
      if (isNaN(years)) return "";
      return years;
    },
    validarTelefono() {
      if (this.datosLead.telefono == null) {
        return [true];
      }

      if (this.datosLead.telefono.match(/\D/)) {
        return ["Teléfono inválido"];
      }
      let phone = this.datosLead.telefono;
      if (phone != null) {
        if (!(phone.length == 10) && !(phone.length == 9))
          return ["Teléfono inválido"];
      }
    },

    validarEmail() {
      if (this.datosLead.email == "") {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.datosLead.email)) 
      {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    getEstadosCv() {
      let lista = [];
      for (let i = 0; i < this.estados.length; i++) {
        if (this.datosLead.estado_cv_id == this.estados[i].status_type_id) {
          lista.push(this.estados[i]);
        }
      }
      return lista;
    },

    getAnuncios() {
      let lista = [];
      for (let i = 0; i < this.anuncios.length; i++) {
        if (
          this.datosLead.marketing_campaign_id ==
          this.anuncios[i].marketing_campaign_id
        ) {
          lista.push(this.anuncios[i]);
        }
      }
      return lista;
    },
  },
  methods: {

    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    
    ...mapActions("access", []),

    abrirCrearLeadDialog(leadId) {
      this.dialogCrearLead = true;

      this.setUrl("lista-partys/" + leadId);
      this.requestApi({
        method: "GET",
        data: {
          typeFind: "cargarDatosProspecto",
          party_id: leadId,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.datosLead = {
            party_id: "",
            status_id: "",
            data_source_id: "",
            acepta_recordatorios: "",
            created_date: "",
            created_by_user_login: "",
            description: "",
            party_type_id: "",
            first_name: "",
            last_name: "",
            telefono: "",
            email: "",
          };
          this.fuentes = res.data.fuentes;
          this.campanias = res.data.campanias;
          this.servicios = res.data.servicios;
          this.anuncios = res.data.anuncios;
          this.estados = res.data.estados;
          this.estadosCv = res.data.estados_cv;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    guardarLead() {
      if (!this.$refs.formLead.validate()) {
        return false;
      }

      this.setLoadingTable(true);
      this.setUrl("lead");
      this.requestApi({
        method: "POST",
        data: this.datosLead,
      })
        .then((res) => {
          //console.log(res.data);
          //this.cargarDatos();
          this.dialogoLead = false;
          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
          this.cargarDatosLead(this.datosLead.party_id);
        })
        .then(() => {
          this.setLoadingTable(false);
        });

      // this.dialogCrearLead = false
    },

    cargarDatosLead(id) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("lista-partys/" + id);
      this.requestApi({
        method: "GET",
        data: {
          typeFind: "cargarDatosProspecto",
          party_id: id,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.datosLead = res.data.party;
          this.opLead = res.data.oportunidades;
          this.eventosLead = res.data.eventos;
          this.fuentes = res.data.fuentes;
          this.campanias = res.data.campanias;
          this.servicios = res.data.servicios;
          this.anuncios = res.data.anuncios;
          this.estados = res.data.estados;
          this.estadosCv = res.data.estados_cv;

          this.pipelines = res.data.pipelines;
          this.tiendas = res.data.tiendas;

          this.provincias = res.data.provincias;
          this.cantones = res.data.cantones;
          this.sectores = res.data.sectores;
          this.ordenesServ = res.data.ordenes_servicio;
          this.segmentos = res.data.segmentos;

          this.ocupaciones = res.data.ocupaciones;
          this.generos = res.data.generos;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },

    crearOportunidad() {
      if (!this.$refs.formCrearOportunidad.validate()) {
        return false;
      }

      this.overlay=true
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "crearOportunidadLead",
          party_id: this.datosLead.party_id,
          product_store_id: this.product_store_id,
          pipeline_id: this.pipeline_id,
          sales_opp_id: this.opp_cot_id,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.overlay= false
          if (res.data.detail.result == true) {
            const routeData = this.$router.resolve({
              name: "CrmCotizacion",
              query: {
                accion: "crear_op",
                sop_id: res.data.detail.sop_id,
                lead_id: this.datosLead.party_id,
                quote_id: res.data.detail.quote_id
              },
            });
            //window.open(routeData.href, "_blank");
            //window.open(routeData.href);
            //window.location.replace(routeData.href);

            this.$router.push({
              path: "/crm_cotizacion",
              query: {
                accion: "crear_op",
                sop_id: res.data.detail.sop_id,
                lead_id: this.datosLead.party_id,
                quote_id: res.data.detail.quote_id
              },
            });
            return;
          }
          this.dialogCotizar = false;
          this.dialogoLead = false;
          this.cargarDatosLead();
        })
        .then(() => {

          this.overlay= false
          this.setLoadingTable(false);

        });
    },

    abrirDialogDireccion() {
      this.dialogDireccion = true;
    },

    guardarDireccion() {
      if (!this.$refs.formCrearOportunidad.validate()) {
        return false;
      }
      let dir = {
        party_id: this.datosLead.party_id,
        contact_mech_id: this.datosLead.direccion_id,
        address1: this.datosLead.address1,
        country_geo_id: this.datosLead.country_geo_id,
        state_province_geo_id: this.datosLead.state_province_geo_id,
        canton_geo_id: this.datosLead.canton_geo_id,
        parroquia_geo_id: this.datosLead.parroquia_geo_id,
        sector: this.datosLead.sector,
      };
      this.setLoadingTable(true);
      this.setUrl("contacto");
      this.requestApi({
        method: "POST",
        data: {
          accion:
            this.datosLead.direccion_id == null
              ? "crearDireccion"
              : "modificarDireccion",
          datos_direccion: dir,
        },
      })
        .then((res) => {
          this.dialogDireccion = false;
          this.cargarDatosLead(this.$route.params.partyId);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarGeo(tipo) {
      let id = "";
      if (tipo == "parroquias-provincia") {
        id = this.datosLead.state_province_geo_id;
        this.datosLead.parroquia_geo_id = "";
        this.datosLead.sector = "";
      }
      if (tipo == "sectores-canton") {
        this.datosLead.canton_geo_id = null;
        for (let i = 0; i < this.cantones.length; i++) {
          if (
            this.cantones[i].parroquia_id == this.datosLead.parroquia_geo_id
          ) {
            this.datosLead.canton_geo_id = this.cantones[i].canton_id;
          }
        }
        id = this.datosLead.canton_geo_id;
        this.datosLead.sector = "";
      }
      this.setLoadingTable(true);
      this.setUrl("geo/" + id + "/" + tipo);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          console.log(res.data.detail);
          if (tipo == "parroquias-provincia") {
            this.cantones = res.data.detail;
          }
          if (tipo == "sectores-canton") {
            this.sectores = res.data.detail;
          }
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    handlePageChange(value) {
      //this.cargarDatos();
    },
  },
  mounted() {
    //this.cargarDatos();
    this.cargarDatosLead(this.$route.params.partyId);
    this.setTitleToolbar("CONTACTO");
  },
};
</script>
